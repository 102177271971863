$(document).on('turbolinks:load', function(e) {
  var processCard = function() {
    var card = {
      name: $(".js-card-name").val(),
      //force credit card validation
      number: $(".js-card-number").val().length > 0 ? $(".js-card-number").val() : "1",
      cvc: $(".js-card-cvc").val(),
      expMonth: $(".js-card-exp-month").val(),
      expYear: $(".js-card-exp-year").val()
    };
  
    return Stripe.createToken(card, handleStripeResponse);
  };
  
  var handleStripeResponse = function(status, response) {
    if (status === 200) {
      $(".js-stripe-card-token").val(response.id);
      return $(".js-credit-card-form")[0].submit();
    } else {
      $(".js-credit-card-form").find("button[type='submit']").attr("disabled", false);
      $(".js-credit-card-fields").show();
      $(".js-credit-card-form").find(".js-loader").hide();
      showErrorMessage("stripe.errors." + response.error.code);
    }
  };
  
  var showErrorMessage = function(errorString) {
    var stripeError;
    stripeError = $(".stripe-error");
    stripeError.text(I18n.t(errorString));
    if (stripeError.is(":hidden")) {
      stripeError.fadeIn("fast");
    } else {
      stripeError.fadeOut("fast", function() {
        $(this).fadeIn("fast");
      });
    }
  };
  
  $(document).on("submit", ".js-stripe-credit-card-form", function(e) {
    e.preventDefault();
    
    if ($(".js-stripe-amount-paid").is(':checked')) {
      Stripe.setPublishableKey($(".js-stripe-public-key").text());
      $(".js-credit-card-form").find("button[type='submit']").attr("disabled", true);
      $(".js-credit-card-fields").hide();
      $(".js-credit-card-form").find(".js-loader").show();
      processCard();
    }else{
      showErrorMessage("todo_amount.required");
    }
  });

});
