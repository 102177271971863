// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or any plugin's vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
// require turbolinks
// require bootstrap
import 'bootstrap-sass/assets/javascripts/bootstrap';
import '../../../vendor/assets/javascripts/bootstrap-multiselect';

// require cocoon
import 'cocoon/app/assets/javascripts/cocoon';

import 'modules/accounts';
import 'modules/general';
import 'modules/documents';
import 'modules/documents-drop-zone';
import 'modules/registration';
import 'modules/forms';
import 'modules/modals';
import 'modules/carousel';
import 'modules/google_sign_in';

import 'public_modules/stripe_public';
import 'modules/doc_collect_items_public';
import 'modules/consent_public';

// require intlTelInput
import 'intl-tel-input/build/js/intlTelInput';
import 'intl-tel-input/build/js/intlTelInput-jquery';
import 'intl-tel-input/build/js/utils.js';

// loading public.scss
import 'stylesheets/public.scss';

import ReactDOM from 'react-dom'
import React from 'react'

//
// ActivityUploader
// -------------------------

import ActivityUploader from '../components/activity_uploader/activity_uploader';

require('@rails/ujs').start();
require('turbolinks').start();
require('channels');

require.context('../images', true,  /\.(?:png|jpg|gif|ico|svg)$/);
require.context('../images/testimonials', true,  /\.(?:png|jpg|gif|ico|svg)$/);

// Setup for the react-rails gem
// https://github.com/reactjs/react-rails
// Support component names relative to this directory:
const componentRequireContext = require.context('../components', true)
const ReactRailsUJS = require('react_ujs')

ReactRailsUJS.useContext(componentRequireContext)

$(document).on('turbolinks:load', function() {
  const containers = document.getElementsByClassName('activity-uploader');
  if (!containers) return;

  const $containers = $(containers);
  $containers.each(function(idx, container){
    const $container = $(container);
    const component = ReactDOM.render(
      <ActivityUploader
        type={$container.data('type')}
        documents={$container.data('documents')}
        activity_id={$container.data('activity-id')}
        doc_collect_item_id={$container.data('doc-collect-item-id')}
        doc_base_idx={$container.data('doc-base-idx')}
        allowed_extensions={$container.data('allowed-extensions')}
        enable_drop_zone={$container.data('enable-drop-zone')}
        recipient_id={($container.data('recipient-id') != null && $container.data('recipient-id').trim().length > 0) ? $container.data('recipient-id') : null}
        recipient_idx={($container.data('recipient-idx') != null && $container.data('recipient-idx').toString().trim().length > 0) ? $container.data('recipient-idx') : null}
        clio_doc_enabled={false}
        single_size={$container.data('single-size')}
        total_size={$container.data('total-size')}
        no_video={$container.data('no-video')}
      />,
      container
    );
    $container.data('react-component', component);
  })
});

//
// GoogleAutocomplete
// -------------------------
window.initAutocomplete = function(...args) {
  const addressSearch = document.getElementById('address_search');
  if (addressSearch) {
    const options = {
      componentRestrictions: { country: [] },
      fields: ['address_components', 'geometry', 'icon', 'name'],
      strictBounds: false,
    };
    const autocomplete = new google.maps.places.Autocomplete(addressSearch, options);
    const infowindow = new google.maps.InfoWindow();
    const infowindowContent = document.getElementById('infowindow-content');

    infowindow.setContent(infowindowContent);

    // Event listener
    autocomplete.addListener('place_changed', function() {
      const place = autocomplete.getPlace();
      const streetAddress = document.getElementById('street_address');
      const city = document.getElementById('city');
      const postalCode = document.getElementById('postal_code');
      const subdivisionCode = document.getElementById('subdivision_code');
      const countryCode = document.getElementById('country_code');

      // Address Parser
      const addressComponents = parseAddressComponents(place.address_components);

      if (streetAddress) streetAddress.value = addressComponents.streetAddress;
      if (city) city.value = addressComponents.city;
      if (postalCode) postalCode.value = addressComponents.postalCode;
      if (countryCode) countryAndStateSelect(countryCode, subdivisionCode, addressComponents.countryCode, addressComponents.subdivisionCode)
      addressSearch.value = null;
    });
  }
}
function countryAndStateSelect(countryCodeElement, subdivisionCodeElement, countryCodeValue, subdivisionCodeValue) {
  const $country_select = $(countryCodeElement);
  const $subdivision_select = $(subdivisionCodeElement);
  $country_select.val(countryCodeValue).change();

  // check if country selection is empty
  if ($country_select.val() === '') {
    // reset the subdivisions
    $subdivision_select.find('option:gt(0)').remove();
    return;
  }

  // Disable select while loading
  $subdivision_select.attr('disabled', 'disabled');

  $.ajax({
    method: 'GET',
    url: $country_select.data('url'),
    data: { country_code: $country_select.val() },
    dataType: 'json',
    cache: false,
  })
  .done((data) => {
    // Clear all <option> except blank state
    $subdivision_select.find('option:gt(0)').remove();
    $.each(data, (i, value) => {
      $subdivision_select.append($('<option>').text(value[0]).attr('value', value[1]));
    });
    if (subdivisionCodeElement) $subdivision_select.val(subdivisionCodeValue).change();
  })
  .always(() => {
    $subdivision_select.removeAttr('disabled');
  });
}

function parseAddressComponents(components) {
  const result = {
    streetAddress: '',
    city: '',
    postalCode: '',
    countryCode: '',
    subdivisionCode: ''
  };

  components.forEach(component => {
    const {types} = component;
    console.log(types)
    console.log(component)
    if (types.includes('street_number')) {
      result.streetAddress = `${component.long_name  } ${  result.streetAddress}`;
    }
    if (types.includes('route')) {
      result.streetAddress += component.long_name;
    }
    if (types.includes('locality')) {
      result.city = component.long_name;
    }
    if (types.includes('postal_code')) {
      result.postalCode = component.long_name;
    }
    if (types.includes('country')) {
      result.countryCode = component.short_name;
    }
    if (types.includes('administrative_area_level_1')) {
      result.subdivisionCode = component.short_name;
    }
  });

  return result;
}

//
// Google Autocomplete initializer
// -------------------------
$(document).on('turbolinks:load', function(e) {
  window.initAutocomplete()
})
